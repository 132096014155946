<template>
  <v-card v-bind="$attrs">
    <v-card-title class="text-subtitle-2 px-4 pt-4 pb-0">
      {{ title }}
    </v-card-title>
    <v-card-text class="px-4 pt-4 pb-0">
      <slot>
        {{ message }}
      </slot>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-spacer />
      <v-btn
        v-if="showCancelAction"
        text
        color="text-primary"
        @click.stop="handleCancel"
      >
        {{ cancelText }}
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="handleConfirm"
      >
        {{ confirmText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
export default {
  name: 'PopupConfirmAction',
  props: {
    cancelText: {
      type: String,
      default: 'cancel'
    },
    confirmText: {
      type: String,
      default: 'confirm'
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    showCancelAction: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const handleCancel = () => {
      props.onCancel();
      emit('close');
    };
    const handleConfirm = async () => {
      try {
        loading.value = true;
        await props.onConfirm();
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };

    return {
      handleConfirm,
      handleCancel,
      loading
    };
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
